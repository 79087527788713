import React, { useState } from 'react';
import axios from 'axios';

const EventForm = ({ onClose, refreshEvents }) => {
  const [eventData, setEventData] = useState({
    title: '',
    location: '',
    date: '',
    time: '',
    entryFee: '',
    helpline: '',
    banner: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEventData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setEventData((prev) => ({
      ...prev,
      banner: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in eventData) {
      formData.append(key, eventData[key]);
    }

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/event/createEvent`, formData);
      // await axios.post('http://192.168.1.8:8000/api/event/createEvent', formData);
      refreshEvents(); // Refresh the events list
      onClose(); // Close the form
      alert('Event added successfully!');
    } catch (error) {
      console.error(error);
      alert('Failed to add event');
    }
  };

  return (
    <div className="popup-form">
      <h2>Add Event</h2>
      <form onSubmit={handleSubmit}>
        <input type="text" name="title" placeholder="Event Title" onChange={handleChange} required />
        <input type="text" name="location" placeholder="Location" onChange={handleChange} required />
        <input type="date" name="date" onChange={handleChange} required />
        <input type="time" name="time" onChange={handleChange} required />
        <input type="text" name="entryFee" placeholder="Entry Fee" onChange={handleChange} required />
        <input type="text" name="helpline" placeholder="Helpline Number" onChange={handleChange} required />
        <input type="file" name="banner" accept="image/*" onChange={handleFileChange} required />
        <button type="submit">Add Event</button>
      </form>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

export default EventForm;
