import React, { useState, useEffect } from 'react';
import './NewResource1.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';

const NewResource = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [registerModalOpen, setRegisterModalOpen] = useState(false);
    const [bannerImage, setBannerImage] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        designation: '',
        message: ''
    });
    const [resources, setResources] = useState([]);
    const [selectedPdf, setSelectedPdf] = useState(null);
    const [selectedResource, setSelectedResource] = useState(null); // Selected resource for editing
    const [editSidebarOpen, setEditSidebarOpen] = useState(false); // Sidebar state

    useEffect(() => {
        const fetchResources = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resource/getResource`);
                setResources(response.data);
            } catch (error) {
                console.error('Error fetching resources:', error);
            }
        };
        fetchResources();
    }, []);

    // Handle file upload changes
    const handleBannerImageChange = (e) => {
        setBannerImage(e.target.files[0]);
    };

    const handlePdfFileChange = (e) => {
        setPdfFile(e.target.files[0]);
    };

    // Handle form submission to add a new resource
    const handleUpload = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('bannerImage', bannerImage);
        formData.append('pdfFile', pdfFile);

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/resource/addresource`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert(response.data.message);
            setBannerImage(null);
            setPdfFile(null);
            const updatedResources = await axios.get(`${process.env.REACT_APP_API_URL}/api/resource/getResource`);
            setResources(updatedResources.data);
        } catch (error) {
            console.error('Error uploading resource:', error);
            alert('Error uploading resource');
        }
    };

    // Handle form data changes for registration
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    // Handle registration form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/userData`, formData);
            setRegisterModalOpen(false);
            alert('Registration successful! You can now access the PDF.');
            if (selectedPdf) {
                const a = document.createElement('a');
                a.href = `${process.env.REACT_APP_API_URL}/${selectedPdf}`;
                a.download = selectedPdf.split('/').pop();
                a.click();
            }
        } catch (error) {
            console.error('Error registering user:', error);
            alert('Error during registration');
        }
    };

    // Handle the PDF download click
    const handleDownloadClick = (link) => {
        setSelectedPdf(link);
        setRegisterModalOpen(true);
    };

    // Handle the Edit action
    const handleEdit = (resource) => {
        setSelectedResource(resource);
        setEditSidebarOpen(true); // Open the sidebar
    };

    // Handle the Delete action
    const handleDelete = async () => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/resource/deleteResource/${selectedResource._id}`);
            alert('Resource deleted');
            setEditSidebarOpen(false); // Close the sidebar after deleting
            const updatedResources = await axios.get('http://192.168.1.33:8000/api/resource/getResource');
            setResources(updatedResources.data);
        } catch (error) {
            console.error('Error deleting resource:', error);
            alert('Error deleting resource');
        }
    };

    // Update resource (Banner or PDF)
    const handleUpdateResource = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        if (bannerImage) formData.append('bannerImage', bannerImage);
        if (pdfFile) formData.append('pdfFile', pdfFile);

        try {
            await axios.put(`${process.env.REACT_APP_API_URL}/api/resource/updateResource/${selectedResource._id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            alert('Resource updated successfully');
            setEditSidebarOpen(false);
            const updatedResources = await axios.get(`${process.env.REACT_APP_API_URL}/api/resource/getResource`);
            setResources(updatedResources.data);
        } catch (error) {
            console.error('Error updating resource:', error);
            alert('Error updating resource');
        }
    };

    return (
        <div className="main-div-resource">
            <h2>Available Resources</h2>
            <div className="resource-add-btn">
                <button onClick={() => setModalOpen(true)} className="add-more-btn">Add More</button>
                {modalOpen && (
                    <div className="modal">
                        <div className="modal-content">
                            <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
                            <h3>Add New Resource</h3>
                            <form onSubmit={handleUpload}>
                                <div className="upload-box">
                                    <label htmlFor="bannerImage">Upload Banner Image</label>
                                    <input
                                        type="file"
                                        id="bannerImage"
                                        onChange={handleBannerImageChange}
                                        required
                                    />
                                </div>
                                <div className="upload-box">
                                    <label htmlFor="pdfFile">Upload PDF</label>
                                    <input
                                        type="file"
                                        id="pdfFile"
                                        onChange={handlePdfFileChange}
                                        required
                                    />
                                </div>
                                <button type="submit">Upload</button>
                            </form>
                        </div>
                    </div>
                )}
            </div>

            <div className="new-resource-container">
    <div className="resource-cards">
        {resources.map((resource) => (
            <div key={resource._id} className="resource-card">
                {/* Ellipsis Icon */}
                <span className="ellipsis-icon" onClick={() => handleEdit(resource)}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </span>

                {/* Sidebar (it should only show when sidebar is open for this resource) */}
                {editSidebarOpen && selectedResource && selectedResource._id === resource._id && (
                    <div className="edit-sidebar">
                        <div className="sidebar-content">
                            <span className="close" onClick={() => setEditSidebarOpen(false)}>&times;</span>
                            <ul className="resource-side">
                                <li className="resource-sidebar">Edit</li>
                                <li className="resource-sidebar" onClick={handleDelete}>Delete Resource</li>
                            </ul>
                        </div>
                    </div>
                )}

                {/* Resource Banner Image */}
                <img
                    src={`${process.env.REACT_APP_API_URL}/${resource.bannerImage}`}
                    alt="Resource"
                    className="resource-banner"
                />

                {/* Button to download PDF */}
                <button
                    className="Resource-btn"
                    onClick={() => handleDownloadClick(resource.pdfFile)}
                >
                    Download PDF
                </button>
            </div>
        ))}
    </div>
</div>


            {/* </div> */}

          
          

           
            {registerModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={() => setRegisterModalOpen(false)}>&times;</span>
                        <h3>Register to Access PDF</h3>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="tel"
                                name="phone"
                                placeholder="Phone"
                                value={formData.phone}
                                onChange={handleChange}
                                required
                            />
                            <input
                                type="text"
                                name="designation"
                                placeholder="Designation"
                                value={formData.designation}
                                onChange={handleChange}
                                required
                            />
                            <textarea
                                name="message"
                                placeholder="Message"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            ></textarea>
                            <button type="submit">Register</button>
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NewResource;



// new code
// import React, { useState, useEffect } from 'react';
// import './NewResource.css';
// import pdf1 from '../assets/pdfFolder/demoPdf.pdf';
// import pdf2 from '../assets/slider-img-new1.jpeg';
// import pdf3 from '../assets/slider-img2.jpeg';
// import Img2 from '../assets/PdfBanner/bannerImg2.jpg';
// import Img4 from '../assets/PdfBanner/pdfBanner-2.webp';
// import Img5 from '../assets/PdfBanner/pdfBanner-3.webp';
// import axios from 'axios';

// const pdfResources = [
//     { title: 'Download PDF 1', link: pdf1, image: Img2 },
//     { title: 'Download PDF 2', link: pdf2, image: Img2 },
//     { title: 'Download PDF 3', link: pdf3, image: Img2 },
//     { title: 'Download PDF 4', link: pdf3, image: Img4 },
//     { title: 'Download PDF 5', link: pdf3, image: Img5 },
// ];

// const NewResource = () => {
//     const [modalOpen, setModalOpen] = useState(false);
//     const [selectedPdf, setSelectedPdf] = useState(null);
//     const [formData, setFormData] = useState({
//         name: '',
//         email: '',
//         phone: '',
//         designation: '',
//         message: ''
//     });
//     const [formError, setFormError] = useState('');
//     const [loading, setLoading] = useState(false);
//     const [currentIndex, setCurrentIndex] = useState(0);
    
//     // Swipe variables
//     const [startX, setStartX] = useState(0);
//     const [isSwiping, setIsSwiping] = useState(false);

//     useEffect(() => {
//         const interval = setInterval(() => {
//             nextSlide();
//         }, 4000);
//         return () => clearInterval(interval);
//     }, [currentIndex]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({ ...formData, [name]: value });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setFormError('');

//         if (!formData.name || !formData.email || !formData.phone || !formData.designation || !formData.message) {
//             setFormError('All fields are required.');
//             return;
//         }

//         setLoading(true);

//         try {
//             await axios.post(`${process.env.REACT_APP_API_URL}/api/userData`, formData);

//             setFormData({
//                 name: '',
//                 email: '',
//                 phone: '',
//                 designation: '',
//                 message: ''
//             });

//             if (selectedPdf) {
//                 const link = document.createElement('a');
//                 link.href = selectedPdf;
//                 link.download = selectedPdf.split('/').pop();
//                 link.click();
//                 setModalOpen(false);
//             }
//         } catch (error) {
//             console.error('Error:', error);
//             setFormError('Failed to submit data. Please try again.');
//         } finally {
//             setLoading(false);
//         }
//     };

//     const handleDownloadClick = (link) => {
//         setSelectedPdf(link);
//         setModalOpen(true);
//     };

//     const nextSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex + 1) % pdfResources.length);
//     };

//     const prevSlide = () => {
//         setCurrentIndex((prevIndex) => (prevIndex - 1 + pdfResources.length) % pdfResources.length);
//     };

//     const getDisplayedResources = () => {
//         return Array.from({ length: 3 }, (_, i) => pdfResources[(currentIndex + i) % pdfResources.length]);
//     };

//     // Swipe Event Handlers
//     const handleTouchStart = (e) => {
//         setStartX(e.touches[0].clientX);
//         setIsSwiping(true);
//     };

//     const handleTouchMove = (e) => {
//         if (!isSwiping) return;
//         const currentX = e.touches[0].clientX;
//         const diffX = startX - currentX;

//         if (diffX > 50) {
//             nextSlide();
//             setIsSwiping(false);
//         } else if (diffX < -50) {
//             prevSlide();
//             setIsSwiping(false);
//         }
//     };

//     const handleTouchEnd = () => {
//         setIsSwiping(false);
//     };

//     return (
//         <div 
//             className="new-resource-container" 
//             onTouchStart={handleTouchStart} 
//             onTouchMove={handleTouchMove} 
//             onTouchEnd={handleTouchEnd}
//         >
//             <h2>Available Resources</h2>

//             <div className="carousel-container">
//                 <button className="carousel-button left" onClick={prevSlide}>‹</button>
//                 <div className="resource-cards">
//                     {getDisplayedResources().map((pdf, index) => (
//                         <div key={index} className="resource-card">
//                             <img src={pdf.image} alt={pdf.title} className="resource-banner" />
//                             <h3>{pdf.title}</h3>
//                             <button onClick={() => handleDownloadClick(pdf.link)}>Download</button>
//                         </div>
//                     ))}
//                 </div>
//                 <button className="carousel-button right" onClick={nextSlide}>›</button>
//             </div>

//             {modalOpen && (
//                 <div className="modal" role="dialog" aria-labelledby="modal-title" aria-modal="true">
//                     <div className="modal-content">
//                         <span className="close" onClick={() => setModalOpen(false)}>&times;</span>
//                         <h3 id="modal-title">Register to Access PDF</h3>
//                         <form onSubmit={handleSubmit}>
//                             {formError && <p className="error">{formError}</p>}
//                             <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} required />
//                             <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
//                             <input type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
//                             <input type="text" name="designation" placeholder="Designation" value={formData.designation} onChange={handleChange} required />
//                             <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange} required></textarea>
//                             <button type="submit" disabled={loading}>{loading ? 'Submitting...' : 'Register'}</button>
//                         </form>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default NewResource;
