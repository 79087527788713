import React, { useState, useEffect } from 'react';
import './PaymentForm.css'; // Import the CSS
import { Link } from 'react-router-dom';
import axios from 'axios'; // Import axios

const PaymentForm = () => {
  // State to manage items
  const [cart, setCart] = useState([]);
  const [initialItems, setInitialItems] = useState([]); // To store exams fetched from the backend

  // Load cart data from local storage when the component mounts
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }

    // Fetch exams from the backend
    fetchExams();
  }, []);

  // Function to fetch exams from the backend using GET request
  const fetchExams = async () => {
    try {
      const response = await axios.get('http://192.168.1.41:8000/api/billing/exams');
      setInitialItems(response.data); // Store the exams in the state
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  // Handle adding items to the cart (only one item at a time)
  const addToCart = (item) => {
    // Only add the item if it's not already in the cart
    setCart([item]);
  };

  // Handle removing items from the cart
  const removeFromCart = () => {
    setCart([]);
  };

  // Calculate the total amount
  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.amount, 0);
  };

  // Function to insert initial data into the backend using POST request
  const insertInitialData = async () => {
    try {
      await axios.post('http://192.168.1.41:8000/api/billing/insert-initial-data');
      alert('Initial data inserted successfully!');
    } catch (error) {
      console.error('Error inserting initial data:', error);
    }
  };

  return (
    <div className="payment-form-container">
      <h3>List of Exams</h3>

      {/* Button to insert initial data (this is for testing purposes, you can remove it after the initial setup) */}
      {/* <button onClick={insertInitialData}>Insert Initial Data</button> */}

      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Title</th>
            <th>Amount</th>
            <th>Cart</th>
          </tr>
        </thead>
        <tbody>
          {initialItems.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.title}</td>
              <td>{item.amount}</td>
              <td>
                <button className="AddToCart" onClick={() => addToCart(item)}>
                  Select
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Cart Table */}
      {cart.length > 0 && (
        <div className="cart-container">
          <h3>Your Selected Exam.</h3>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Sub Total</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>{item.amount}</td>
                  <td>
                    <button onClick={removeFromCart}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Total and Checkout Button */}
          <div className="total-container">
            <h3>Total: ₹{calculateTotal()}</h3>
            <Link to="/billing-info">
              <button className="checkout-btn" onClick={() => alert('Proceeding to checkout')}>
                Checkout
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
