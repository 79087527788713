import React, { useState, useEffect } from 'react';
import axios from 'axios';
import EventForm from '../pages/EventForm'; // Import the new EventForm component
import Modal from '../pages/Modal'; // Import the Modal component
import './AdminPage.css'; // Import the CSS file

const AdminPage = () => {
  const [events, setEvents] = useState([]);
  const [isFormOpen, setFormOpen] = useState(false);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/event/getEvent`);
      // const response = await axios.get('http://192.168.1.8:8000/api/event/getEvent');
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div className="admin-page">
      <h2>Events</h2>
      <button className="add-event-button" onClick={() => setFormOpen(true)}>Add Event</button>
      
      <Modal isOpen={isFormOpen} onClose={() => setFormOpen(false)}>
        <EventForm onClose={() => setFormOpen(false)} refreshEvents={fetchEvents} />
      </Modal>

      <div className="event-list">
        <h3>Current Events</h3>
        <div className="event-cards">
          {events.map((event) => (
            <div key={event.id} className="Upcoming-event-card">
              <img src={`${process.env.REACT_APP_API_URL}/${event.banner}`} alt={event.title} className="event-banner" />
              {/* <img src={`http://192.168.1.8:8000/${event.banner}`} alt={event.title} className="event-banner" /> */}
              <div className="event-details">
                <h4 className="event-title">{event.title}</h4>
                <p className="event-location"><strong>Location:</strong> {event.location}</p>
                <p className="event-date"><strong>Date:</strong> {event.date} at <strong>Time:</strong> {event.time}</p>
                
                <p className="event-entry-fee"><strong>Entry Fee:</strong> {event.entryFee}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
