import React, { useState, useEffect } from 'react';
import Loading from './componant/Loading'; // Ensure the path is correct
import LoginPage from './LoginPage'; // Import your LoginPage
import App from './App'; // The main content after login


const App2 = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkAuthentication = () => {
      const userDetails = localStorage.getItem('userDetails');
      if (userDetails) {
        const { timestamp } = JSON.parse(userDetails);
        // Check if the timestamp is still valid
        if (Date.now() < timestamp) {
          setIsAuthenticated(true);
        } else {
          localStorage.removeItem('userDetails'); // Clear expired details
          console.log("Session expired, please log in again."); // Debugging message
        }
      } else {
        console.log("No user details found."); // Debugging message
      }
      setLoading(false); // Stop loading once check is done
    };

    // Simulate loading time
    const timer = setTimeout(checkAuthentication, 2000);

    return () => clearTimeout(timer);
  }, []);

  const handleLoginSuccess = (userDetails) => {
    setIsAuthenticated(true);
    // Set expiration time to 1.8 seconds (1800 milliseconds)
    localStorage.setItem('userDetails', JSON.stringify({ ...userDetails, timestamp: Date.now() + 1800 }));
    console.log("User logged in successfully."); // Debugging message
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : isAuthenticated ? (
        <App /> // Render your main application content
      ) : (
        <LoginPage onLoginSuccess={handleLoginSuccess} />
      )}
      
    </>
  );
};

export default App2;
