import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './CpsRegisterForm.css';
import AssignedQuestionDetails from './AssignedQuestionDetails';

const ExamAppoiment = () => {
  const { rollNumber: rollNumberFromURL } = useParams(); // Fetch rollNumber from URL params
  const [rollNumber, setRollNumber] = useState(rollNumberFromURL || ''); // Allow both URL and manual entry
  const [assignedQuestionsCount, setAssignedQuestionsCount] = useState(0);
  const [newQuestionLength, setNewQuestionLength] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch the assigned questions count based on entered roll number
  const fetchAssignedQuestionsCount = async () => {
    if (!rollNumber) return; // Avoid making requests with an empty roll number

    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_API_URLII}/api/exam/assigned-count/${rollNumber}`);
      if (response.data && response.data.count !== undefined) {
        setAssignedQuestionsCount(response.data.count);
      } else {
        toast.error('No data found for the entered Roll Number.');
        setAssignedQuestionsCount(0);
      }
    } catch (error) {
      console.error('Error fetching assigned questions count:', error);
      toast.error('Error fetching data. Please try again.');
      setAssignedQuestionsCount(0);
    } finally {
      setLoading(false);
    }
  };

  // Trigger fetching when rollNumber changes
  useEffect(() => {
    if (rollNumber) {
      fetchAssignedQuestionsCount();
    }
  }, [rollNumber]);

  // Function to adjust the question length and update the questions
  const adjustQuestions = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URLII}/api/exam/adjust-questions`, {
        rollNumber: rollNumber,  // The roll number entered by the user
        question_length: newQuestionLength,  // The new question length entered by the user
      });

      if (response.status === 200) {
        toast.success(response.data.message);
      }
    } catch (error) {
      console.error('Error adjusting questions:', error);
      toast.error('Error adjusting questions.');
    }
  };

  // Handle Form Submission for updating question length and adjusting questions
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newQuestionLength || newQuestionLength <= 0) {
      toast.error('Please enter a valid question length.');
      return;
    }

    if (!rollNumber) {
      toast.error('Please enter a valid Roll Number.');
      return;
    }

    await adjustQuestions();  // Call the function to adjust questions
  };

  // Handle manual input of Roll Number
  const handleRollNumberChange = (e) => {
    setRollNumber(e.target.value);
  };

  // Handle Search manually (use navigate if necessary)
  const handleSearchClick = () => {
    if (rollNumber) {
      fetchAssignedQuestionsCount(); // Fetch based on user input
    } else {
      toast.error('Please enter a valid Roll Number.');
    }
  };

  return (
    <div>
      <div className="GEM-form-div-bkg">
        <div className="main-form-div-popup">
          <div className="register-form">
            <form onSubmit={handleSubmit}>
              <div className="rollNumber-box">
                <div className="form-fields-data">
                  <label>Roll Number*</label>
                  <input
                    type="text"
                    placeholder="Enter Roll Number"
                    value={rollNumber}
                    onChange={handleRollNumberChange}
                    required
                  />
                </div>
                <div className="button-Search-2">
                  <button type="button" onClick={handleSearchClick} disabled={loading}>
                    {loading ? 'Searching...' : 'Search'}
                  </button>
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Assigned Questions Count</label>
                  <input
                    type="text"
                    value={assignedQuestionsCount}
                    readOnly
                    required
                  />
                </div>

                <div className="form-fields-data">
                  <label>Enter New Question Length</label>
                  <input
                    type="number"
                    value={newQuestionLength}
                    onChange={(e) => setNewQuestionLength(e.target.value)}
                    required
                    min="1"
                  />
                </div>
              </div>

              <div className="button-group-2">
                <button type="submit">Update & Adjust Questions</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    <AssignedQuestionDetails />
      <ToastContainer />
    </div>
  );
};

export default ExamAppoiment;
