
import './App.css';
import { HashRouter, Routes, Route } from 'react-router-dom';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';

// import Register1 from './pages/Register1';
import NewNavbar from './componant/NewNavbar';

import DashBord from './pages/DashBord';
import UpcomingEvent from './EventsPage/UpcomingEvent';
import PastEvent from './EventsPage/PastEvent';
import ExamAppoiment from './NavbarPages/ExamAppoiment';
import AssignQuestion from './NavbarPages/AssignQuestion';
import Resource from './componant/Resource';
import ResourceSlider from './SliderPages/ResourceSlider';
import NewResource from './componant/NewResource'
import AdminPage from './pages/AdminPage'
import UpdateBillingInfo from './NavbarPages/UpdateBillingInfo';
import PaymentForm from './NavbarPages/PaymentForm';
import UploadInitialDataForm from './NavbarPages/UploadInitialDataForm';
import OfflineRegistration from './NavbarPages/OfflineRegistration';
// import StepLayout from './SliderPages/StepLayout';

// import NewGeoEcoSystem from './pages/NewGeoEcoSystem';
// import EcoSystem from './pages/EcoSystem';


export default function App() {
  return (
    <HashRouter>
    {/* <BrowserRouter> */}
      {/* <SubHeader /> */}
      {/* <Navbar /> */}
      <div className='App_main-div'>
        <div className='Nav-box'>
      < NewNavbar />
      </div>
      <div className='routes_div'>
      <Routes>
        <Route path="/" element={<DashBord />} />
        <Route path="/upcoming-events" element={<AdminPage />} />
        <Route path="/past-events" element={<PastEvent />} />
        <Route path="/reschedule-appoinment" element={<ExamAppoiment />} />
        <Route path="/reschedule-appoinment/:invoiceId" element={<ExamAppoiment />} />
        <Route path="/assign-quiz" element={<AssignQuestion/>} />
        <Route path="/assign-quiz/:rollNumber" element={<AssignQuestion/>} />
        <Route path="/resources" element={<NewResource/>} />
        <Route path="/gem-payment-info" element={<UpdateBillingInfo/>} />
        <Route path="/billing-info" element={<PaymentForm/>} />
        <Route path="/manage-payment" element={<UploadInitialDataForm/>} />
        <Route path="/manage-offline-payment" element={<OfflineRegistration/>} />
        
     
        {/* <Route path="/event/:id" element={<EventDetails />} /> */}

        {/* Footer links  */}
        {/* <Route path="/faq" element={<FAQ />} /> */}
      </Routes>
      </div>
      </div>
      {/* <StepLayout /> */}
      {/* <Footer /> */}
      {/* <CopyRight /> */}
    </HashRouter>
    
  );
}
