import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate, useParams } from 'react-router-dom';
import './CpsRegisterForm.css';

const countryStateData = {
  India: [
    'Andhra Pradesh', 'Arunachal Pradesh', 'Assam', 'Bihar', 'Chhattisgarh',
    'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand',
    'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur',
    'Meghalaya', 'Mizoram', 'Nagaland', 'Odisha', 'Punjab',
    'Rajasthan', 'Sikkim', 'Tamil Nadu', 'Telangana', 'Tripura',
    'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands',
    'Chandigarh', 'Dadra and Nagar Haveli and Daman and Diu', 'Lakshadweep',
    'Delhi', 'Puducherry', 'Jammu and Kashmir', 'Ladakh',
  ],
};

const OfflineRegistration = () => {
  const navigate = useNavigate();
  const { invoiceId } = useParams(); // Capture invoiceId from the URL
  const [paymentStatus, setPaymentStatus] = useState('');
  const [initialItems, setInitialItems] = useState([]); // To store exams fetched from the backend
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('cart')) || []);
  const [formData, setFormData] = useState({
    salutation: '',
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    country: 'India',
    state: '',
    city: '',
    pincode: '',
    mobile: '',
    telephone: '',
    message: '',
    
    invoiceId: '',
    paymentStatus: 'Pending' || 'Paid',
    cart: [],
  });
  const [loading, setLoading] = useState(false);

  // Fetch data when invoiceId is available
  useEffect(() => {
    if (invoiceId) {
      localStorage.setItem('invoiceId', invoiceId); // Store in localStorage
      fetchFormData(invoiceId);
    }
  }, [invoiceId]);

  // Fetch form data based on invoiceId
  const fetchFormData = async (invoiceId) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URLII}/api/billing/billinginfo/${invoiceId}`);
      if (response.data && response.data.data) {
        const fetchedCart = response.data.data.cart ? JSON.parse(response.data.data.cart) : []; // Parse the cart data
        setFormData({
          invoiceId: response.data.data.invoiceId,
          salutation: response.data.data.salutation || '',
          first_name: response.data.data.first_name || '',
          last_name: response.data.data.last_name || '',
          email: response.data.data.email || '',
          address: response.data.data.address || '',
          country: response.data.data.country || 'India',
          state: response.data.data.state || '',
          city: response.data.data.city || '',
          pincode: response.data.data.pincode || '',
          mobile: response.data.data.mobile || '',
          telephone: response.data.data.telephone || '',
          message: response.data.data.message || '',
         
          paymentStatus: response.data.data.paymentStatus || '',

          cart: fetchedCart,  // Set parsed cart data here
        });
        console.log(response.data.data)
        toast.success('Form data loaded successfully!');
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
      toast.error('Error loading form data.');
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    const enteredInvoiceId = formData.invoiceId;
    if (!enteredInvoiceId) {
      toast.error('Please enter a valid Invoice ID');
      setLoading(false);
      return;
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URLII}/api/billing/billinginfo/${enteredInvoiceId}`);
      if (response.data && response.data.data) {
        const fetchedCart = response.data.data.cart ? JSON.parse(response.data.data.cart) : [];
        setFormData({
          invoiceId: response.data.data.invoiceId,
          salutation: response.data.data.salutation || '',
          first_name: response.data.data.first_name || '',
          last_name: response.data.data.last_name || '',
          email: response.data.data.email || '',
          address: response.data.data.address || '',
          country: response.data.data.country || 'India',
          state: response.data.data.state || '',
          city: response.data.data.city || '',
          pincode: response.data.data.pincode || '',
          mobile: response.data.data.mobile || '',
          telephone: response.data.data.telephone || '',
          message: response.data.data.message || '',
          
          paymentStatus: response.data.data.paymentStatus || '',
          cart: fetchedCart,  // Set parsed cart data here
        });
        toast.success('Form data loaded successfully!');
        console.log(response.data.data)
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
      toast.error('No data found for the entered Invoice ID.');
    } finally {
      setLoading(false);
    }
  };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!formData.first_name || !formData.last_name) {
//       toast.error('Please enter both First and Last Name');
//       return;
//     }

//     const storedInvoiceId = localStorage.getItem('invoiceId');
//     if (!storedInvoiceId) {
//       toast.error('Invoice ID not found');
//       return;
//     }

//     const formDataWithInvoice = {
//       ...formData,
//       paymentStatus: paymentStatus, // Explicitly add paymentStatus here
//       invoiceId: storedInvoiceId,
//     };

//     try {
//       await axios.patch(`${process.env.REACT_APP_API_URLII}/api/billing/billinginfo/${invoiceId}/payment-status`, formDataWithInvoice);
//       toast.success('Form submitted successfully!');
//     } catch (error) {
//       toast.error('Error submitting form. Please try again.');
//     }
//   };


// const handleSubmit = async (e) => {
//   e.preventDefault();

//   if (!formData.first_name || !formData.last_name) {
//     toast.error('Please enter both First and Last Name');
//     return;
//   }

//   const invoiceId = localStorage.getItem('invoiceId');
//   if (!invoiceId) {
//     toast.error('Invoice ID is missing!');
//     return;
//   }

//   const formDataWithInvoice = {
//     ...formData,
//     paymentStatus: formData.paymentStatus,  // Ensure paymentStatus is included
//     invoiceId: invoiceId,
//   };

//   try {
//     const response = await axios.patch(
//       `${process.env.REACT_APP_API_URLII}/api/billing/billinginfo/${invoiceId}/payment-status`,
//       {
//         data: { paymentStatus }  // Send paymentStatus inside the 'data' object
//       }
//     );
//     console.log('Payment status updated successfully:', response.data);
//     toast.success('Form submitted successfully!');
//   } catch (error) {
//     toast.error('Error submitting form. Please try again.');
//     console.error('Error submitting data:', error);
//   }
// };

// const updatePaymentStatus = async (paymentStatus) => {
  // Debug: log paymentStatus to check if it's correct
  // console.log('Updating paymentStatus:', paymentStatus);

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  
  //   if (!formData.first_name || !formData.last_name) {
  //     toast.error('Please enter both First and Last Name');
  //     return;
  //   }
  
  //   const invoiceId = localStorage.getItem('invoiceId');
  //   if (!invoiceId) {
  //     toast.error('Invoice ID is missing!');
  //     return;
  //   }
  
  //   const formDataWithInvoice = {
  //     ...formData,
  //     invoiceId: invoiceId, // Ensure invoiceId is added here
  //   };
  
  //   try {
  //     const response = await axios.patch(
  //       `${process.env.REACT_APP_API_URLII}/api/billing/billinginfo/${invoiceId}/payment-status`,
  //       {
  //         data: { paymentStatus: formData.paymentStatus } // Payment status inside 'data' object
  //       }
  //     );
  //     console.log('Payment status updated successfully:', response.data);
  //     toast.success('Form submitted successfully!');
  //   } catch (error) {
  //     toast.error('Error submitting form. Please try again.');
  //     console.error('Error submitting data:', error);
  //   }
  // };


  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form Data Before Sending:", formData);  // Debugging step
    
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URLII}/api/billing/billinginfo/${formData.invoiceId}/payment-status`,
        { data: { paymentStatus: formData.paymentStatus } }  // Ensure the format is correct
      );
      console.log("Response:", response.data);
      toast.success('Payment status updated successfully!');
    } catch (error) {
      console.error('Error updating payment status:', error);
      toast.error('Error submitting form.');
    }
  };


  
  const calculateTotal = () => {
    if (formData.cart && formData.cart.length > 0) {
      return formData.cart.reduce((total, item) => {
        const amount = parseFloat(item.amount);
        if (!isNaN(amount)) {
          return total + amount;
        }
        return total;
      }, 0);
    } else {
      return 0; // Return 0 if cart is empty
    }
  };

  const removeFromCart = (index) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  return (
    <div>
      <div className="GEM-form-div-bkg">
        <div className="main-form-div-popup">
          <div className="register-form">
            <form onSubmit={handleSubmit}>

              <div className="invoiceId-box">
                <div className="form-fields-data">
                  <label>Invoice Id*</label>
                  <input
                    type="text"
                    placeholder="Enter Invoice ID"
                    value={formData.invoiceId}
                    onChange={(e) => setFormData({ ...formData, invoiceId: e.target.value })}
                    required
                  />
                </div>
                <button type="button" className="invoice-check-btn" onClick={handleSearch} disabled={loading}>
                  {loading ? 'Searching...' : 'Search'}
                </button>
              </div>

              <h3 className="Gem-form-heading">Please Select Date and Timing for Your Exam.</h3>

              {/* Form Fields */}
              <div className="form-fields">
                <div className="form-fields-data">
                  <label>First Name *</label>
                  <input
                    type="text"
                    placeholder="First Name"
                    value={formData.first_name}
                    onChange={(e) => setFormData({ ...formData, first_name: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Last Name *</label>
                  <input
                    type="text"
                    placeholder="Last Name"
                    value={formData.last_name}
                    onChange={(e) => setFormData({ ...formData, last_name: e.target.value })}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Email Id *</label>
                  <input
                    type="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Address Line *</label>
                  <input
                    type="text"
                    placeholder="Address Line"
                    value={formData.address}
                    onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                    required
                    disabled
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>Country *</label>
                  <select
                    value={formData.country}
                    onChange={(e) => setFormData({ ...formData, country: e.target.value })}
                    required
                  >
                    <option value="" disabled>Select your country</option>
                    {Object.keys(countryStateData).map((countryName, index) => (
                      <option key={index} value={countryName}>{countryName}</option>
                    ))}
                  </select>
                </div>

                <div className="form-fields-data">
                  <label>State *</label>
                  <select
                    value={formData.state}
                    onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                    required
                    disabled
                  >
                    <option value="" disabled>Select your state</option>
                    {formData.country === 'India' ? (
                      countryStateData[formData.country].map((stateName, index) => (
                        <option key={index} value={stateName}>{stateName}</option>
                      ))
                    ) : (
                      <option value="" disabled>Select a country to see states</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data">
                  <label>City *</label>
                  <input
                    type="text"
                    placeholder="City"
                    value={formData.city}
                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Pincode *</label>
                  <input
                    type="text"
                    placeholder="Pin Code"
                    value={formData.pincode}
                    onChange={(e) => setFormData({ ...formData, pincode: e.target.value })}
                    required
                  />
                </div>
              </div>

              <div className="form-fields">
                <div className="form-fields-data flex-container">
                  <label>Mobile No. *</label>
                  <input
                    className="phone"
                    type="text"
                    placeholder="Phone Number"
                    value={formData.mobile}
                    onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                    required
                    disabled
                  />
                </div>

                <div className="form-fields-data">
                  <label>Telephone No. (With STD Code)</label>
                  <input
                    type="tel"
                    placeholder="Telephone Number"
                    value={formData.telephone}
                    onChange={(e) => setFormData({ ...formData, telephone: e.target.value })}
                  />
                </div>

                <div className="form-fields-data">
  <label>Payment Status *</label>
  <select
    value={formData.paymentStatus}
    onChange={(e) => setFormData({ ...formData, paymentStatus: e.target.value })}
    required
  >
    <option value="Paid">Paid</option>
    <option value="Pending">Pending</option>
  </select>
</div>
              </div>

              {/* Cart Details */}
              <div className="form-fields">
                {formData.cart.length > 0 && (
                  <div className="cart-container" id="SelectedBillingItem">
                    <h3>Your Selected Exam.</h3>
                    <table id="billingTable">
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'center' }}>Title</th>
                          <th>Sub Total</th>
                          <th>Remove</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formData.cart.map((item, index) => (
                          <tr key={item.id}>
                            <td>{item.title}</td>
                            <td>{item.amount}</td>
                            <td>
                              <button onClick={() => removeFromCart(index)}>Remove</button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div className="total-container">
                      <h3>Total: ₹{calculateTotal()}</h3>
                    </div>
                  </div>
                )}
              </div>

              {/* Submit Button */}
              <div className="form-fields-data">
                <button type="submit">Submit</button>
                {/* <button onClick={() => updatePaymentStatus("Paid")}>Mark as Paid</button> */}
              </div>

            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OfflineRegistration;
