
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import logo1 from '../assets/New_logo_1.png';
import logo3 from '../assets/Gem_new_Logo2.png';
import logo4 from '../assets/logo-tm-removebg.png';
import './NewNavbar.css';

const NewNavbar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const sticky = window.scrollY > 0;
            setIsSticky(sticky);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Function to toggle the menu
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Function to close the menu
    const closeMenu = () => {
        setMenuOpen(false);
    };

    return (
        <nav className={`navbar ${isSticky ? 'sticky' : ''}`}>
            <div className="logo-container">
                <Link id='img-log1' to="/"><img src={logo4} alt="Logo 1" className="logo" id='img-logo1' /></Link>
                <Link to="/"><img src={logo3} alt="Logo 2" className="logo" id='img-logo2' /></Link>
            </div>

            <div
                className={`menu-icon ${menuOpen ? 'rotate' : ''}`}
                onClick={toggleMenu} // Toggle the menu on icon click
            >
                <FontAwesomeIcon icon={menuOpen ? faXmark : faBars} />
            </div>

            <ul className={`nav-links ${menuOpen ? 'open' : ''}`}>
                <li><Link to="/" onClick={closeMenu}>DashBord</Link></li>
                {/* <li><Link to="/gem-rating" onClick={closeMenu}>Update Events</Link></li>
                <li><Link to="/ecoSystem" onClick={closeMenu}>Upadate Resource</Link></li> */}
                {/* <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Certification</Link></li> */}
                {/* <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Certification</Link></li> */}
                {/* <li><Link to="/Sustainability" onClick={closeMenu}>Sustainability Solutions</Link></li> */}
                {/* <li className="dropdown">
                    <Link>GEM Ecosystem</Link>
                    <ul className="dropdown-menu">
                       
                        <li><Link to="/eco-products" onClick={closeMenu}>GEM Eco-Products</Link></li>
                        <li><Link to="/gemGreen" onClick={closeMenu}>GEM Green Building Certification</Link></li>
                    </ul>
                </li> */}
                <li className="dropdown">
                    <Link >Update Events</Link>
                    <ul className="dropdown-menu">
                        <li><Link to="/upcoming-events" onClick={closeMenu}>Upcoming Events</Link></li>
                        <li><Link to="/past-events" onClick={closeMenu}>Past Events</Link></li>
                        {/* <li><Link to="/past-events" onClick={closeMenu}>Past Events</Link></li> */}
                    </ul>
                </li>
                <li className="dropdown">
                    <Link>GEM Certified Professionals Exam</Link>
                    <ul className="dropdown-menu">
                
                            <li><Link to="/manage-payment" onClick={closeMenu}>Manage Payment</Link></li>
                            {/* <li><Link to="/manage-order" onClick={closeMenu}>Manage Order</Link></li> */}
                            <li><Link to="/manage-appoinment" onClick={closeMenu}>Manage Appoinment</Link></li>
                            <li><Link to="/manage-offline-payment" onClick={closeMenu}>Manage offline Payment</Link></li>


                </ul>
                </li>
                <li className="dropdown">
                    <Link>Manage Questions </Link>
                    <ul className="dropdown-menu">
                
                            <li><Link to="/manage-question" onClick={closeMenu}>Manage Question</Link></li>
                            <li><Link to="/assign-quiz" onClick={closeMenu}>Assign Question</Link></li>
                            <li><Link to="/reschedule-appoinment" onClick={closeMenu}>Reschedule Appoinment</Link></li>
                            <li><Link to="/quiz-result" onClick={closeMenu}>Quiz Result</Link></li>


                </ul>
                </li>
                {/* <li><Link to="/register" onClick={closeMenu}>Register For Projects</Link></li> */}
                <li><Link to="/resources" onClick={closeMenu}>Resources</Link></li>
            </ul>
        </nav>
    );
};

export default NewNavbar;
