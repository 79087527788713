// frontend/src/components/UpdateBillingInfo.jsx
import React, { useState } from 'react';

const UpdateBillingInfo = () => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('Pending');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = { name, address, contact, payment_status: paymentStatus };
    const response = await fetch('http://localhost:8000/api/billing/add', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const result = await response.json();
    alert(result.message);
  };

  return (
    <div>
      <h3>Update Billing Information</h3>
      <form onSubmit={handleSubmit}>
        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <input type="text" placeholder="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
        <input type="text" placeholder="Contact" value={contact} onChange={(e) => setContact(e.target.value)} />
        <select value={paymentStatus} onChange={(e) => setPaymentStatus(e.target.value)}>
          <option value="Pending">Pending</option>
          <option value="Paid">Paid</option>
        </select>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default UpdateBillingInfo;
