import React from 'react'
import './DashBord.css'
import { Link } from 'react-router-dom';
const cardData = [
    { imgSrc: 'cardImg/cardImg-1.png', text: 'Total Project Registration',  },
    // { imgSrc: 'cardImg/cardImg-1.png', text: 'GEM Green Building Certification', route: '/gem-green' },
    { imgSrc: 'cardImg/cardImg-2.png', text: "Total GEM Professional's Registration",  },
    // { imgSrc: 'cardImg/cardImg-2.png', text: 'GEM Eco-Product Certification', route: '/gem-product' },
    { imgSrc: 'cardImg/cardImg-3.png', text: 'Total Number of Appoinment',   },
    { imgSrc: 'cardImg/cardImg-4.png', text: 'Total GEM Enquiry', },
    { imgSrc: 'cardImg/cardImg-5.png', text: 'Total No. of Certified Professional',  },
//     { imgSrc: 'cardImg/cardImg-6.png', text: 'ISO 14001 conformity', route: '/iso-14001' },
//     { imgSrc: 'cardImg/cardImg-7.png', text: 'Environmental Management Plans', route: '/environmental-management' },
//     { imgSrc: 'cardImg/cardImg-8.png', text: 'Water Audits', route: '/water-audits' },
//     { imgSrc: 'cardImg/cardImg-9.png', text: 'Energy Audits', route: '/energy-audits' },
];

const DashBord = () => {
  return (
    <div>
      <div className="sustainability-container">
                      <div className="cards-container">
                          {cardData.map((card, index) => (
                              // <Link to={card.route} 
                              <div key={index} className="cardSatn-Dashord">
                                  {/* <div className="card-img">
                                      <img src={require(`../assets/${card.imgSrc}`)} alt={`Sustainability Goal ${index + 1}`} />
                                  </div> */}
                                  <div className="card-text">{card.text}</div>
                                  <div className="card-text-cd"><p className="cd-text">300</p></div>
                                 </div>
                              
                          ))}
                      </div>
                      </div>
    </div>
  )
}

export default DashBord
