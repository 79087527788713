import React, { useState } from 'react';
import './LoginPage.css';

const LoginPage = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleLogin = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === 'password') {
      onLoginSuccess({ username });
      setError('');
    } else {
      setError('Invalid username or password');
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    console.log('Password reset details:', { email, phone, newPassword, reEnterPassword });
  };

  return (
    <div className="login-container">
      {forgotPassword ? (
        <form onSubmit={handleForgotPassword}>
          <h1>Green Assocham </h1>
          <h2>Reset Password</h2>
          
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="tel"
            placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="New Password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Re-Enter Password"
            value={reEnterPassword}
            onChange={(e) => setReEnterPassword(e.target.value)}
            required
          />
          <button type="submit">Reset Password</button>
        </form>
      ) : (
        <form onSubmit={handleLogin}>
          <h1>Green Assocham</h1>
          <h2>Login</h2>
          {error && <p className="error">{error}</p>}
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button className='login-btn' type="submit">Login</button>
          <p className="forgot-password" onClick={() => setForgotPassword(true)}>Forget Password?</p>
        </form>
      )}
    </div>
  );
};

export default LoginPage;
