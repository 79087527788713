import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns'; // Importing date-fns for formatting
import './AssignedQuestionDetails.css'; // Custom CSS for table responsiveness

const AssignedQuestionDetails = () => {
  const [assignedDetails, setAssignedDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAssignedDetails = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URLII}/api/exam/assigned-details`);  // Fetch all records
        setAssignedDetails(response.data.userQuestions);  // Store results in state
        setLoading(false);
      } catch (err) {
        setError('Error fetching assigned question details');
        setLoading(false);
      }
    };

    fetchAssignedDetails();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  if (assignedDetails.length === 0) return <div>No assigned question details found</div>;

  return (
    <div className="table-container">
      <h2>Assigned Question Details</h2>
      <table className="assigned-questions-table">
        <thead>
          <tr>
            <th>Id</th>
            <th>Roll Number</th>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Question Length</th>
            <th>Created At</th>
          </tr>
        </thead>
        <tbody>
          {assignedDetails.map((detail) => (
            <tr key={detail.rollNumber}>
              <td>{detail.id}</td>
              <td>{detail.rollNumber}</td>
              <td>{detail.first_Name}</td>
              <td>{detail.last_Name}</td>
              <td>{detail.email}</td>
              <td>{detail.question_length}</td>
              <td>{format(new Date(detail.created_at), 'yyyy-MM-dd HH:mm:ss')}</td>
              {/* <td>{detail.created_at}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AssignedQuestionDetails;
