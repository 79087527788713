import React, { useState, useEffect } from 'react';
import './UploadInitialDataForm.css'
import axios from 'axios'; // Import axios for making API requests
import { Link } from 'react-router-dom';

const UploadInitialDataForm = () => {
  const [title, setTitle] = useState('');
  const [amount, setAmount] = useState('');
  const [message, setMessage] = useState('');

  const [cart, setCart] = useState([]);
  const [initialItems, setInitialItems] = useState([]); // To store exams fetched from the backend

  // Load cart data from local storage when the component mounts
  useEffect(() => {
    const savedCart = JSON.parse(localStorage.getItem('cart'));
    if (savedCart) {
      setCart(savedCart);
    }

    // Fetch exams from the backend
    fetchExams();
  }, []);

  // Function to fetch exams from the backend using GET request
  const fetchExams = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/billing/exams`);
      setInitialItems(response.data); // Store the exams in the state
    } catch (error) {
      console.error('Error fetching exams:', error);
    }
  };

  // Handle adding items to the cart (only one item at a time)
  const addToCart = (item) => {
    // Only add the item if it's not already in the cart
    setCart([item]);
  };

  // Handle removing items from the cart
  const removeFromCart = () => {
    setCart([]);
  };

  // Calculate the total amount
  const calculateTotal = () => {
    return cart.reduce((total, item) => total + item.amount, 0);
  };

  // Function to insert initial data into the backend using POST request
  const insertInitialData = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/billing/insert-initial-data`);
      alert('Initial data inserted successfully!');
    } catch (error) {
      console.error('Error inserting initial data:', error);
    }
  };

  // Function to update exam data
  const updateExam = async (id, updatedTitle, updatedAmount) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/billing/update-exam`, {
        id,
        title: updatedTitle,
        amount: updatedAmount,
      });
      alert('Exam updated successfully!');
      fetchExams(); // Refresh the exams list after updating
    } catch (error) {
      console.error('Error updating exam:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      title,
      amount: parseFloat(amount), // Make sure amount is treated as a float
    };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/billing/insert-exam`, data);
      setMessage('Data uploaded successfully!');
      console.log(response.data);
    } catch (error) {
      setMessage('Error uploading data.');
      console.error(error);
    }
  };

  return (
    <div>
      <h2 className='ExamPaymentHeading'>Upload Initial Data</h2>
      <form onSubmit={handleSubmit}>
        <div className="payment_exam_box">
        <div className='payment_title'>
          <label>Title</label>
          <textarea
            type='text'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className='Payment_amout'>
          <label>Amount</label>
          <input
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <button className='payment-submit' type="submit">Upload</button>
        </div>
      </form>

      {message && <p>{message}</p>}

      <div className="payment-form-container-BE">
      <h3>List of Exams</h3>

      {/* Button to insert initial data (this is for testing purposes, you can remove it after the initial setup) */}
      {/* <button onClick={insertInitialData}>Insert Initial Data</button> */}

      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Title</th>
            <th>Amount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {initialItems.map((item, index) => (
            <tr key={item.id}>
              <td className='payment_info_index'>{index + 1}</td>
<td>
  <textarea
    value={item.title}
    onChange={(e) => {
      const updatedItems = [...initialItems];
      updatedItems[index].title = e.target.value;
      setInitialItems(updatedItems);
    }}
  />
</td>
<td>
  <textarea
    type="number"
    value={item.amount}
    onChange={(e) => {
      const updatedItems = [...initialItems];
      updatedItems[index].amount = parseFloat(e.target.value);
      setInitialItems(updatedItems);
    }}
  />
</td>
              <td>
                <div className="payment_update_btn">
                <button className='payment_button'
                  onClick={() => updateExam(item.id, item.title, item.amount)}
                >
                  Update
                </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Cart Table */}
      {cart.length > 0 && (
        <div className="cart-container">
          <h3>Your Selected Exam</h3>
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Sub Total</th>
                <th>Remove</th>
              </tr>
            </thead>
            <tbody>
              {cart.map((item, index) => (
                <tr key={index}>
                  <td>{item.title}</td>
                  <td>{item.amount}</td>
                  <td>
                    <button onClick={removeFromCart}>Remove</button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Total and Checkout Button */}
          <div className="total-container">
            <h3>Total: ₹{calculateTotal()}</h3>
            <Link to="/billing-info">
              <button className="checkout-btn" onClick={() => alert('Proceeding to checkout')}>
                Checkout
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
    </div>
  );
};


export default UploadInitialDataForm;
